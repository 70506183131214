import React from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomContainer = styled(Box)({
  // width: '90%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#1BA8F1', // Adjust the color to match the image's background
  padding: '50px 20px',
});

const Content = styled(Box)({
  maxWidth: '1000px',
  position: 'relative',
  color: '#ffffff', // Adjust the color to match the image's text color
  textAlign: 'left',
});

const VerticalBars = styled('div')({
  position: 'absolute',
  top: 0,
  left: '-40px', // Adjusted to make sure it aligns with the edge
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '20px 0',

  '&::before, &::after, div': {
    content: '""',
    width: '5px',
    backgroundColor: '#ffffff',
    margin: '20px 0',
  },

  '&::before': {
    height: '100px', // Large
  },

  div: {
    height: '60px', // Medium
  },

  '&::after': {
    height: '40px', // Small
  },
});

const SmallRectangle = styled('div')({
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  width: '50px',
  height: '50px',
  backgroundColor: '#33c0f4', // Slightly lighter color
  opacity: 0.5, // Slightly transparent
});

const advantagePoints = [
  {
    title: 'Vertical Integration',
    description: 'Lunex Power is a fully integrated company, with internal engineers, permitting, project management and installation technicians. Avoiding subcontracting means improved installation speed and quality.',
  },
  {
    title: 'Competitive Warranties',
    description: '10 year commercial warranties offered on both workmanship/labor and penetrations. Protecting you in the unlikely event anything were to go wrong with your system.',
  },
  {
    title: 'Reputation',
    description: 'Averaging over 1,000 solar installations annually and maintaining an almost 5-star rating online is no small feat. You can trust you are in safe and proven hands with Lunex.',
  },
  {
    title: 'Community Involvement',
    description: 'Lunex Power is the official solar company of USF Athletics and Tampa Bay Rays, with members of our executive team belonging to both, the Tampa and St. Pete Chambers of Commerce. Community involvement is a cornerstone value of Lunex.',
  },
  {
    title: 'Permit Processing',
    description: 'We are very seasoned in the permit process throughout Florida. We manage the permit with you to avoid any confusion and unnecessary delays.',
  },
  {
    title: 'Value and Return',
    description: 'Your solar system will last a very long time. Dollar for dollar, which company is bringing you the best return and payback period?',
  },
  {
    title: 'Smaller, More Responsive Team',
    description: 'Lunex Power is a more intimate contractor experience than many big-box national solar installers. We provide a tailored and much more communicative experience for our clients.',
  },
];

const Advantage = () => {
  return (
    <CustomContainer>
      <Content>
        <Typography variant="h3" component="h3" sx={{ color: '#FFA500', textAlign: 'left', marginBottom: '20px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}>
          LUNEX POWER ADVANTAGE
        </Typography>
        <Typography variant="h5" component="p" sx={{ color: '#000', textAlign: 'left', marginBottom: '40px' }}>
          Selecting a contractor for your solar project can be an overwhelming task. Compiled here is a list of important questions you must ask yourself to ensure you select the right company for the job. Lunex has a proven track record of exceeding expectations. If you’re seeking solutions it's best to go right to the pure source.
        </Typography>
        <List>
          {advantagePoints.map((point, index) => (
            <ListItem key={index} sx={{ alignItems: 'flex-start' }}>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: '#FFA500', fontSize: '2.5rem' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h5" component="span" sx={{ color: '#000', fontWeight: 'bold', marginRight: '10px' }}>
                    {point.title}
                  </Typography>
                }
                secondary={
                  <Typography variant="h6" component="div" sx={{ color: '#ffffff', marginTop: '8px' }}>
                    {point.description}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        <VerticalBars>
          <div></div>
        </VerticalBars>
        {/* <SmallRectangle /> */}
      </Content>
    </CustomContainer>
  );
};

export default Advantage;
