import styled, { keyframes } from 'styled-components'
import hero_img from '../../images/Images/Homepage/MainBanner.webp'
import { Link as LinkScroll } from 'react-scroll';

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
    margin-top: -80px;
    font-family: 'Avenir_Book';
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const ImageBg = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${hero_img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
    }

`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -300px;

    @media screen and (max-width: 900px) {
        margin-left: 0;
    }
`

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: left;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const arrow = keyframes`
    0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
`;

export const ScrollArrow = styled(LinkScroll)`
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 50px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-left: none;
    border-top: none;
    border-right: 2px #fff solid;
    border-bottom: 2px #fff solid;
    cursor: pointer;

    &:before {
        position: absolute;
        left: 50%;
        content: '';
        width: 20px;
        height: 20px;
        top: 50%;
        margin: -10px 0 0 -10px;
        border-left: none;
        border-top: none;
        border-right: 1px #fff solid;
        border-bottom: 1px #fff solid;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: ${arrow};
    }

    @media screen and (max-width: 768px) {
        width: 30px;
        height: 30px;

        &:before {
            width: 15px;
            height: 15px;
        }
        
    }
`;

